import React from 'react';
export const SizeContext = React.createContext(
    {
        width: 1000,
        height: 1000
    }
  );

  export const DateContext = React.createContext(
    {
        date: "",
        time: "",
        weekDay: ""
    }
  );