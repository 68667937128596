import {Button} from "./VideoInterfaces";

export const buttons: Array<Button> = [
    {
        position: {
            x: 390,
            y: 625.50
        }, 
        size: {
            width: 215,
            height: 70
        },
        videoIndex: 1,
        video: "mission_coordinates.mp4",
        endVideo: ["coordinates_loop.mp4", "coordinates_loop1.mp4"],
        displayDate: false
    },

    {
        position: {
            x: 1300,
            y: 420
        }, 
        size: {
            width: 150,
            height: 60
        },
        videoIndex: 2,
        video: "mission_tips.mp4",
        endVideo: ["tips_loop.mp4", "tips_loop1.mp4"],
        displayDate: false
    },

    {
        position: {
            x: 1490.50,
            y: 614.50
        }, 
        size: {
            width: 171,
            height: 80
        },
        videoIndex: 4,
        video: "mission_time.mp4",
        endVideo: ["time_loop.mp4", "time_loop1.mp4"],
        displayDate: true
    }
]