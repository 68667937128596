import React, { useEffect, useContext, useState } from 'react';
import './App.css';
import { Welcome } from './UI/Welcome';
import { SizeContext, DateContext } from './context';
import { Presentation } from './UI/Presentation';

export const App: React.FC = ({}) => {
  const [showWelcome, setShowWelcome] = useState(true);
  const [showPresentation, setShowPresentation] = useState(false);
  const [showReplay, setShowReplay] = useState(false);

  const context = useContext(SizeContext);
  const dateContext = useContext(DateContext);

  useEffect(()=> {
    getSize();
    window.addEventListener('resize', handleResize);
    window.addEventListener("orientationchange", handleResize);
    window.screen.orientation.addEventListener("change", handleResize);
    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("time")) {
      var time = urlParams.get('time');
      var timestamp: number = Number(time);
      if (!isNaN(timestamp)) {
        
        const date = new Date(timestamp); // This would be the timestamp you want to format

        const month = date.toLocaleString('default', { month: 'long' });
        const weekDay = date.toLocaleString('default', { weekday: 'long' });
        dateContext.date = date.getDate() + " " + month + " " + date.getFullYear();

        var currentHours = ('0'+date.getHours()).slice(-2);
        var currentMinutes = ('0'+date.getMinutes()).slice(-2);
        dateContext.time = currentHours + ":" + currentMinutes;
        dateContext.weekDay = "(" + weekDay + ")";
      }
    }
  },[])

  const handleResize = () => {
    getSize();
  }

  const getSize = () => {
    var ratio = 2048.0 / 1080.0;
    var windowHeight = Math.min(window.screen.height, window.innerHeight);
    var windowWidth = Math.min(window.screen.width, window.innerWidth);

    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    if (iOSSafari) {
      windowHeight = window.innerHeight;
      windowWidth = window.innerWidth;
    }

    var webWidth = windowWidth;
    var webHeight = windowWidth / ratio;

    if (webHeight > windowHeight) {
        webHeight = windowHeight - 2;
        webWidth = webHeight * ratio;
    }

    context.width = webWidth;
    context.height = webHeight
  
    var element = document.getElementById("app");
    if (element) {
      element.style.width = webWidth + "px"; 
      element.style.height = webHeight + "px"; 
      element.style.left = (windowWidth - webWidth)/2 + "px"; 
    }
  }

  return (
    <div className="App" id="app">
      {showWelcome && 
        <Welcome replay = {showReplay}
        onEnd={()=>{setShowPresentation(true); setShowWelcome(false);}}/>
      }

      {showPresentation &&
      <Presentation onEnd={()=>{setShowPresentation(false); setShowWelcome(true); setShowReplay(true)}}/>
      }
    </div>
  );
}

export default App;
