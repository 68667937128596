import React, { useEffect, useState, useContext } from 'react';
import {VideoLoop} from "./VideoInterfaces";
import { BorderButton } from './Components/BorderButton';
import ReactPlayer from 'react-player'
import './Presentation.css';
import { SizeContext, DateContext } from '../context';
import {buttons} from './Constants';
import { ImageButton } from './Components/ImageButton';
import { Form } from './Components/Form';
import { VerticallyAlignedButton } from './Components/VerticallyAllignedButton';

interface PresentationProps{
    onEnd: () => void;
}

export const Presentation: React.FC<PresentationProps> = ({onEnd}: PresentationProps) => {
    const [displayAmbienceVideo, setDisplayAmbienceVideo] = useState("intro2.mp4");
    const [displayNextAmbienceVideo, setDisplayNextAmbienceVideo] = useState("main.mp4");

    const [playAmbienceVideo, setPlayAmbienceVideo] = useState(true);
    const [playNextAmbienceVideo, setPlayNextAmbienceVideo] = useState(false);

    const [countPlayedVideo, setCountPlayedVideo] = useState(0);
    const [showButtons, setShowButtons] = useState(true);

    const [showDate, setShowDate] = useState(false);

    const [videoWidth, setVideoWidth] = useState('300px');
    const [videoHeight, setVideoHeight] = useState('71px');

    const [scaleWidth, setScaleWidth] = useState(10);
    const [scaleHeight, setScaleHeight] = useState(10);

    const [blackoutVideoIsPlayed, setBlackoutVideoIsPlayed] = useState(false);

    const [displayNextAmbienceVideoStatus, setDisplayNextAmbienceVideoStatus] = useState<Array<String>>([]);

    const context = useContext(SizeContext);

    const dateContext = useContext(DateContext);

      const onScreenresize = () => {
        var ratio = 2048.0 / 1080.0;

        var windowWidth = context.width;

        var widthLimit = windowWidth ;
        var heightLimit = widthLimit / ratio;

        setVideoHeight(heightLimit + 'px');
        setVideoWidth(widthLimit +'px');

        var imageHeight = 1080;
        var imageWidth = 2048;
        setScaleHeight(context.height / imageHeight);
        setScaleWidth(context.width / imageWidth);
      }


    useEffect(() => {
        window.addEventListener("resize", onScreenresize);
        window.addEventListener("orientationchange", onScreenresize);
        window.screen.orientation.addEventListener("change", onScreenresize);

        var windowWidth = context.width;

        var ratio = 2048.0 / 1080.0;

        var widthLimit = 0.9 * windowWidth ;
        var heightLimit = widthLimit / ratio;

        setVideoHeight(heightLimit + 'px');
        setVideoWidth(widthLimit +'px');

        var imageHeight = 1080;
        var imageWidth = 2048;
        setScaleHeight(context.height / imageHeight);
        setScaleWidth(context.width / imageWidth);

        return () => {
            window.removeEventListener('resize', onScreenresize);
            window.removeEventListener('orientationchange', onScreenresize);
            window.screen.orientation.removeEventListener('change', onScreenresize);
          };

    },[])

    

    const onAmbienceVideoEnd = () => {
        var allVideosArePlayed = true;
        for (var button of buttons) {
            if (!videoIsPlayed(button.videoIndex)) {
                allVideosArePlayed = false;
            }
        }
        if (!allVideosArePlayed) {
            setPlayAmbienceVideo(false);
            setPlayNextAmbienceVideo(true);
            startPlayingVideo();
            setShowButtons(true);
        } else {
            if (!blackoutVideoIsPlayed) {
                setPlayAmbienceVideo(false);
                setPlayNextAmbienceVideo(true);
                setTimeout(function(){
                    setShowDate(false);
                    setPlayNextAmbienceVideo(false);
                    setPlayAmbienceVideo(true);
                    setBlackoutVideoIsPlayed(true);
                    setDisplayAmbienceVideo("blackout.mp4");
                }, 5000);
            } else {
                setShowDate(false);
                onEnd()
            }
        }
    }

     function videoIsPlayed(index: number) {
        return (countPlayedVideo & index) == index ;
    }

    const onPlayVideo = (index: number) => {
        setPlayAmbienceVideo(false);
        setPlayNextAmbienceVideo(false);
        
        var button = buttons[index];
        var video = button.video;

        if (!videoIsPlayed(button.videoIndex)) {
            setCountPlayedVideo(oldValue => oldValue + button.videoIndex);
        }

        if (button.displayDate) {
            setTimeout(function(){
                setShowDate(button.displayDate);
            }, 1000);
        } else {
            setShowDate(false);
        }
        setPlayAmbienceVideo(true);
        setPlayNextAmbienceVideo(false);

        setDisplayAmbienceVideo(video);

        if (displayNextAmbienceVideoStatus.includes(button.video)) {
            setDisplayNextAmbienceVideo(button.endVideo[1]);
            var _displayNextAmbienceVideoStatus = displayNextAmbienceVideoStatus;
            var index = displayNextAmbienceVideoStatus.indexOf(button.video);
            if (index !== -1) {
                _displayNextAmbienceVideoStatus.splice(index, 1);
            }
            setDisplayNextAmbienceVideoStatus(_displayNextAmbienceVideoStatus);
        } else {
            setDisplayNextAmbienceVideoStatus(oldArray => [...oldArray, button.video]);
            setDisplayNextAmbienceVideo(button.endVideo[0]);
        }
        setShowButtons(false);

    }

    function startPlayingVideo() {
        let videoPlayer = (document.getElementsByTagName('video') as HTMLCollectionOf<HTMLVideoElement> | null);
        if (videoPlayer && videoPlayer.length > 1) {
            videoPlayer[1].play();
        } 
    }

    return (
        <>
       
        <ReactPlayer 
        style={{ display: playAmbienceVideo ? "block" : "none" }}
                    playing={playAmbienceVideo}
                    url={"videos/" + displayAmbienceVideo}
                    width='100%'
                    height='100%'
                    loop={false}
                    muted={false}
                    pip={false}
                    controls={false}
                    config={{ file: { attributes: { playsInline: true, }, }, }}
                    onEnded={onAmbienceVideoEnd}
                />

        <ReactPlayer 
        style={{ display: playNextAmbienceVideo ? "block" : "none" }}
                    playing={false}
                    url={"videos/" + displayNextAmbienceVideo}
                    width='100%'
                    height='100%'
                    loop={true}
                    muted={false}
                    pip={false}
                    controls={false}
                    config={{ file: { attributes: { playsInline: true, }, }, }}
                    //onEnded={onNextAmbienceVideoEnd}
                />
    
         <div className='Date' style={{opacity: showDate ? 1 : 0}}>
             <div style={{position: 'absolute', top: 80 * scaleHeight, left: 830 * scaleWidth}}>
             DATE: {dateContext.date}
             </div>
             <div style={{position: 'absolute', top: 120 * scaleHeight, left: 935 * scaleWidth}}>
                {dateContext.weekDay}
             </div>
             <div style={{position: 'absolute', top: 160 * scaleHeight, left: 830 * scaleWidth}}>
             TIME: {dateContext.time}
            </div>
        </div>
    
        {showButtons && buttons.map((button, index) => {
        return (
            <div key={index} style={{position: 'absolute', width: button.size.width * scaleWidth, height: button.size.height * scaleHeight, top: button.position.y * scaleHeight, left: button.position.x * scaleWidth}}>
          
            <BorderButton
                onClick={() => onPlayVideo(index)}
            />
            </div> 
        );
      })}
        </>
    )
}